<template>
  <div class="grid" v-if="clienteData">
    <div class="col-12">
      <h6 class="">Administração de Venda</h6>
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Salvar" icon="pi pi-save" class="p-button-success mr-2" @click="saveCliente" />
            <Button label="Exportar" icon="pi pi-download" class="mr-2 p-button-outlined" @click="exportCSV($event)"  />
            <Button label="Importar" icon="pi pi-upload" class="mr-2 p-button-outlined" @click="exportCSV($event)"  />
          </div>
        </template>
        <template v-slot:end>
          <Button label="Voltar" icon="pi pi-backward" class="p-button-outlined mr-2" @click="$router.go(-1)" />
        </template>
      </Toolbar>
    </div>

    <div class="col-12">
      <TabView>
        <TabPanel header="Cliente">

          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="cpf">CPF</label>
              <div class="p-inputgroup">
                <InputText placeholder="000.000.000-00" v-model="clienteData.cpf" :readonly="true" />
                <!--                <Button label="Pesquisar CPF"/>-->
              </div>
            </div>


            <div class="field col-12 md:col-6">
              <label for="nome">Nome</label>
              <InputText id="nome" type="text" v-model="clienteData.nome" :readonly="true" />
            </div>

            <div class="field col-12 md:col-6">
              <label for="email">E-mail</label>
              <InputText id="email" type="text" v-model="clienteData.email" :readonly="true" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="telefone">Telefone</label>
              <InputText id="telefone" type="text" v-model="clienteData.telefone" :readonly="true" />
            </div>



            <div class="field col-12 md:col-6">
              <label for="cep">CEP</label>
              <div class="p-inputgroup">
                <InputText placeholder="00.000-000" v-model="clienteData.cep" :readonly="true" />

              </div>
            </div>

            <div class="field col-12 md:col-6">
              <label for="cidade">Cidade</label>
              <InputText id="cidade" type="text" v-model="clienteData.cidade" :readonly="true" />
            </div>

            <div class="field col-12 md:col-6">
              <label for="logradouro">Logradouro</label>
              <InputText id="logradouro" type="text" v-model="clienteData.logradouro" :readonly="true" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="numero">Número</label>
              <InputText id="numero" type="text" v-model="clienteData.numero" :readonly="true" />
            </div>


            <div class="field col-12 md:col-6">
              <label for="estado">Estado</label>
              <Dropdown id="estado" v-model="clienteData.estado" :options="dropdownItems" optionLabel="name" placeholder="ES"  :readonly="true" ></Dropdown>
            </div>

            <div class="field col-12">
              <label for="obs">Observações</label>
              <Textarea id="obs" rows="4" v-model="clienteData.obs"  :readonly="true" />
            </div>


          </div>

        </TabPanel>



        <TabPanel header="Local de Instalação">
          <div class="p-fluid formgrid grid">

            <div class="field col-12 md:col-6">
              <label for="cep">CEP</label>
              <div class="p-inputgroup">
                <InputText placeholder="00.000-000" v-model="clienteData.cep" :readonly="true" />
                <Button label="Pesquisar CEP"/>
              </div>
            </div>

            <div class="field col-12 md:col-6">
              <label for="cidade">Cidade</label>
              <InputText id="cidade" type="text" v-model="clienteData.cidade" :readonly="true" />
            </div>

            <div class="field col-12 md:col-6">
              <label for="logradouro">Logradouro</label>
              <InputText id="logradouro" type="text" v-model="clienteData.logradouro" :readonly="true" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="numero">Número</label>
              <InputText id="numero" type="text" v-model="clienteData.numero" :readonly="true" />
            </div>


            <div class="field col-12 md:col-6">
              <label for="estado">Estado</label>
              <Dropdown id="estado" v-model="clienteData.estado" :options="dropdownItems" optionLabel="name" placeholder="ES"  :readonly="true" ></Dropdown>
            </div>

            <div class="field col-12">
              <label for="obs">Observações</label>
              <Textarea id="obs" rows="4" v-model="clienteData.obs"  :readonly="true" />
            </div>

          </div>
        </TabPanel>


        <TabPanel header="Sistema">

          <div class="p-fluid formgrid grid">

            <div class="field col-12 md:col-6">
              <label for="consmed">Consumo Médio Cliente KWh</label>
              <InputText id="consmed" type="text" v-model="clienteData.consmedio"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="potsist">Potência do Sistema KWp</label>
              <InputNumber id="potsist" v-model="clienteData.potsist"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="tarifene">Tarifa de Energia R$/KWh</label>
              <InputNumber id="tarifene" v-model="clienteData.tarifene"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="consmensol">Custo mensal conta com Energia Solar</label>
              <InputNumber id="consmensol" v-model="clienteData.consmensol"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="taxailum">Taxa de Iluminação</label>
              <InputNumber id="taxailum" v-model="clienteData.taxailum"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="tipoconex">Tipo de Conexão</label>
              <InputText id="tipoconex" v-model="clienteData.tipoconex"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="datavalid">Data de Validade</label>
              <InputText id="datavalid" v-model="clienteData.datavalid"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="efisist">Eficiência Sistema</label>
              <InputNumber id="efisist" v-model="clienteData.efisist"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="germedmen">Geração média mensal estimada KWH</label>
              <InputNumber id="germedmen" v-model="clienteData.germedmen"/>
            </div>
            <div class="field col-12 md:col-6">
              <label for="tipoestr">Tipo de Estrutura</label>
              <Dropdown id="estado" v-model="clienteData.tipoestr" :options="estruturaItems" optionLabel="name" placeholder="Selecione tipo de estrutura"  ></Dropdown>
            </div>
            <div class="field col-12 md:col-6">
              <label for="cidadeirrad">Cidade Irradiação</label>
              <InputText id="cidadeirrad" v-model="clienteData.cidadeirrad"/>
            </div>
          </div>

        </TabPanel>


        <TabPanel header="Documentos">


          <div class="grid">
            <div class="col-3 align-items-center justify-content-center">

              <Divider layout="horizontal" align="center">
                <span class="">Pessoa Jurídica</span>
              </Divider>
              <h6>Cartão CNPJ</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />
              <h6>Contrato Social</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />
              <h6>Faturamento</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />
              <h6>Conta de Energia</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />
              <h6>RG e CPF dos Sócios</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />
              <h6>Arquivos Adicionais</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

            </div>
            <div class="col-1">
              <Divider layout="vertical">
                <b></b>
              </Divider>
            </div>

            <div class="col-3 align-items-center justify-content-center">

              <Divider layout="horizontal" align="center">
                <span class="">Pessoa Física</span>
              </Divider>

              <h6>RG e CPF</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Comprovante de Residência</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Contra Cheque</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Imposto de Renda</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Conta de Energia</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Arquivos Adicionais</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />


            </div>

            <div class="col-1">
              <Divider layout="vertical">
                <b></b>
              </Divider>
            </div>


            <div class="col-3 align-items-center justify-content-center">

              <Divider layout="horizontal" align="center">
                <span class="">Avalista</span>
              </Divider>

              <h6>RG e CPF</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Comprovante de Residência</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Contra Cheque</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Imposto de Renda</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />

              <h6>Arquivos Adicionais</h6>
              <FileUpload choose-label="Upload de arquivo" mode="basic" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @upload="onUpload" />


            </div>

          </div>



        </TabPanel>



        <TabPanel header="Valores">

          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="maoobra">Mão de Obra</label>
              <InputNumber id="maoobra" v-model="clienteData.maoobra" mode="currency" currency="BRL" locale="pt-BR" />
            </div>

            <div class="field col-12 md:col-6">
              <label for="deslocamento">Deslocamento</label>
              <InputNumber id="deslocamento" v-model="clienteData.deslocamento" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="projeto">Projeto de Engenharia</label>
              <InputNumber id="projeto" v-model="clienteData.projeto" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="imposto">Imposto</label>
              <InputNumber id="imposto" v-model="clienteData.imposto" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="impostoserv">Imposto Serviços</label>
              <InputNumber id="impostoserv" v-model="clienteData.impostoserv" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="margemserv">Margem Serviço</label>
              <InputNumber id="margemserv" v-model="clienteData.margemserv" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="hospedagem">Hospedagem</label>
              <InputNumber id="hospedagem" v-model="clienteData.hospedagem" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="estrutura">Estrutura</label>
              <InputNumber id="estrutura" v-model="clienteData.estrutura" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="paineis">Painéis</label>
              <InputNumber id="paineis" v-model="clienteData.paineis" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="iversores">Iversores</label>
              <InputNumber id="iversores" v-model="clienteData.iversores" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-6">
              <label for="valorkit">Valor Kit</label>
              <InputNumber id="valorkit" v-model="clienteData.valorkit" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
            <div class="field col-12 md:col-12">
              <label for="valortotal">Valor Total Projeto</label>
              <InputNumber id="valortotal" v-model="clienteData.valorkit" mode="currency" currency="BRL" locale="pt-BR" />
            </div>
          </div>
        </TabPanel>


        <TabPanel header="Pagamento">

          <div class="p-fluid formgrid grid">

            <div class="field col-12 md:col-12">
              <label for="formapgto">Forma de Pagamento</label>
              <Dropdown id="formapgto" v-model="clienteData.formapgto" :options="formaItems" optionLabel="name" placeholder="Selecione a forma de pagamento"  ></Dropdown>
            </div>


            <div class="field col-12">
              <label for="obspgto">Observações</label>
              <Textarea id="obspgto" rows="4" v-model="clienteData.obspgto"  :readonly="true" />
            </div>

          </div>

        </TabPanel>

        <TabPanel header="Leads">

          <DataTable v-if="leadsData" :value="leadsData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">

            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/leads')" />
              </template>
            </Column>

            <Column field="code" header="Código" :style="{width:'100px'}" ></Column>

            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>

            <Column field="representante" header="Representante" :style="{width:'200px'}"></Column>
            <Column field="inventoryStatus" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'lead-badge status-' + (data.inventoryStatus ? data.inventoryStatus.toLowerCase() : '')">{{data.inventoryStatus}}</span>
              </template>
            </Column>

            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>

            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.price)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>

        <TabPanel header="Orçamentos">

          <DataTable v-if="orcamentosData" :value="orcamentosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/orcamentodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'orcamento-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


        <TabPanel header="Obras">

          <DataTable v-if="obrasData" :value="obrasData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/obradetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'obra-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>



        <TabPanel header="Projetos">


          <DataTable v-if="projetosData" :value="projetosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/projetodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'projeto-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>


        <TabPanel header="Financeiro">
          Em andamento
        </TabPanel>

      </TabView>


    </div>

    <div class="col-12">
      <cliente-detalhe-timeline></cliente-detalhe-timeline>
    </div>




  </div>
</template>
<script>
import ClienteDetalheTimeline from "@/components_esol/clientes/ClienteDetalheTimeline";
import LeadService from "@/service/LeadService";

export default {
  components: {ClienteDetalheTimeline},
  data() {
    return {
      pedidosData: null,
      orcamentosData: null,
      obrasData: null,
      projetosData: null,

      loadingLeads: true,
      leadService: null,
      leadsData: null,
      clienteData: null,
      estruturaItems: [
        {name: 'Solo', code: 'Solo'},
        {name: 'Trapezoidal', code: 'Trapezoidal'},
        {name: 'Fibrocimento Parafuso Metal', code: 'Fibrocimento Parafuso Metal'},
        {name: 'Fibrocimento Parafuso Madeira', code: 'Fibrocimento Parafuso Madeira'},
        {name: 'Ondulada', code: 'Ondulada'},
        {name: 'Romagnole', code: 'Romagnole'},
        {name: 'Laje', code: 'Laje'},
        {name: 'Colonial', code: 'Colonial'},
        {name: 'Zipada', code: 'Zipada'},
      ],
      formaItems: [
        {name: 'Financiamento', code: 'Financiamento'},
        {name: 'Boleto', code: 'Boleto'},
        {name: 'PIX', code: 'PIX'},
      ],
      bancoItems: [
        {name: 'Sicoob', code: 'Sicoob'},
      ],
      dropdownItems: [
        {name: 'ES', code: 'ES'},
        {name: 'MG', code: 'MG'},
        {name: 'RJ', code: 'RJ'}
      ],
      dropdownItem: null,
    }
  },

  created() {
    this.leadService = new LeadService();

  },

  mounted() {
    this.clienteData =
        {
          cpf: '123.456.789-00',
          codigo: '123',
          nome: 'Cliente Testes 001',
          cep: '123.123-00',
          cidade: 'Belo Horizonte',
          logradouro: 'Rua testes',
          email: 'comercial@dominio.com.br',
          telefone: '00.1234-1234',
          numero: '032',
          estado: 'MG',
          obs: ''
        }

    this.leadService.getLeads().then((data) => {
      console.log('orcamentoDetalhe_0')
      this.leadsData = data
      this.loadingLeads = false
      console.log(this.leadsData)
      this.leadsData.forEach(lead => lead.date = new Date(lead.date));
    });


    this.leadService.getOrcamentos().then((data) => {
      this.orcamentosData = data
      // console.log(this.orcamentosData)
      this.orcamentosData.splice(0,6)
      // console.log(this.orcamentosData)
      this.orcamentosData.forEach(orcto => orcto.date = new Date(orcto.date));
    });

    this.leadService.getPedidos().then((data) => {
      this.pedidosData = data
      // console.log(this.pedidosData)
      this.pedidosData.splice(0,3)
      // console.log(this.pedidosData)
      this.pedidosData.forEach(pedido => pedido.date = new Date(pedido.date));
    });

    this.leadService.getObras().then((data) => {
      this.obrasData = data
      // console.log(this.pedidosData)
      this.obrasData.splice(0,5)
      // console.log(this.pedidosData)
      this.obrasData.forEach(obra => obra.date = new Date(obra.date));
    });

    this.leadService.getProjetos().then((data) => {
      this.projetosData = data
      // console.log(this.pedidosData)
      this.projetosData.splice(0,8)
      // console.log(this.pedidosData)
      this.projetosData.forEach(obra => obra.date = new Date(obra.date));
    });


  },

  methods: {
    onUpload() {

    },

    saveCliente() {

    },
    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },

    formatCurrency(value) {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
